import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Asciinema from '../components/Asciinema'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="turnMeGreen">Hi people</h1>
    <p>Welcome to your new Gatsby sass scaffold.</p>
    <p>This site will allow you to experiment with sass and scss files to see how they are compiled and implemented within a site.</p>
    <h2> 0. Ensure you have gatsby and it's dependencies installed.</h2>
    <p>
      <a href="https://www.gatsbyjs.com/docs/tutorial/part-zero/">Offical gatsby tutorial on setting up your development environment.</a>
    </p>
    <p>
     In addition to gatsby you will also want to install yarn "npm i -g yarn", as we are using namespaces in our theme.
    </p>
    <h2> 1. Ensure you have your ssh keys uploaded to Gitlab </h2>
    <p>
     <Link to="/ssh-keys/">Go to ssh page to learn how to upload an ssh key into Gitlab.</Link> <br />
    </p>
    <h2> 2. Watch the Initialization video.</h2>
    <Asciinema localpath='../assets/sass-test.rec' />
    <h2> 3. Watch the branching video.</h2>
    <p>
     <Link to="/branching/">Go to the branching page to learn all about making a branch and submitting a merge request.</Link> <br />
    </p>
  </Layout>
)

export default IndexPage
