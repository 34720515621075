import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby-link'

export default class Asciinema extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <>
      <h3>Initialization run</h3>
        <asciinema-player src={this.props.localpath}></asciinema-player>
      </>
  }
}
